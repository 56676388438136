import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Animation from './Animation'
import SEO from './SEO'
import BigCloud from "../../content/animations/GrosNuage.json";
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from './Commons'
import LayoutGeneral from "./Layouts"
import { media } from '../tokens'

const SiteContent = styled.div`
   
    @media ${media.maxMedium} {
        padding: 80px 0px;
      }
`

const CloudContent = styled.div`
    height: 750px;
    position:relative;
    padding-top: 180px;
    max-width: 600px;
    margin: 0 auto;
`

const CloudAnimationParent = styled.div`
    .animation-container{
        position: absolute;
        width:1920px;
        height:800px;
        //bottom: -250px;
        right:25%;
        left:50%;
        margin-left:-960px;
    }
`


export default (props) => {
    const { children, location, title, description, noCover } = props
    return (
        <LayoutGeneral location={location} noCover={noCover}>
            <SEO
            title={title}
            description={description}
            path={location}
            />

            <SiteContent>

                <CloudAnimationParent>
                    <Animation animation={BigCloud}></Animation>
                </CloudAnimationParent>

                <CloudContent>
                {children}
                </CloudContent>
        
            </SiteContent>
            
        </LayoutGeneral>
    )
}