import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from '../components/Commons'
import LayoutBigCloud from "../components/LayoutBigCloud"
import { media } from '../tokens'
import useSiteMetadata from '../hooks/use-site-config'

const Text1 = styled.h1`
    text-align: center;
    font-size: 2em;
    color:black;
    position:relative;
    //font-family: 'Verdana, Geneva, sans-serif';
    font-weight: bold;
`

const Text2 = styled.h4`
    text-align: center;
    padding-top: 50px;
    font-size: 1.5em;
    color:black;
    position:relative;
    //font-family: 'Verdana, Geneva, sans-serif';
    font-weight: bold;
`

export default () =>{ 
    const { supportEmail, socialDiscord } = useSiteMetadata()

    return (
    <LayoutBigCloud location="/Forum/" title="FORUM" >
            <Text1>
                <br/>
                Sorry the forum is not ready yet
            </Text1>
            <Text2>
                Contact us by email at {supportEmail} or by <a href={socialDiscord}>Discord</a>
            </Text2>
    </LayoutBigCloud>
)
}